import { Image as ImageType } from '@yleisradio/areena-types';
import Image from 'next/image';
import React from 'react';
import { loaderUrl, logoImageLoader } from 'utils/cloudinary';
import styles from './Logo.module.scss';

interface LogoImageProps {
  image: ImageType;
}

export const Logo: React.FunctionComponent<LogoImageProps> = ({ image }) => (
  <Image
    alt=""
    className={styles.logo}
    key={image.id}
    loader={logoImageLoader}
    priority
    sizes="(min-width: 1020px) 251px, (min-width: 800px) 205px, 161px"
    src={loaderUrl(image)}
    width={270}
    height={148}
  />
);
