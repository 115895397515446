import { ListStyle, Pointer } from '@yleisradio/areena-types';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useCards } from 'hooks/useCards';
import { useProgress } from 'hooks/useProgress';
import { getCardKey, getHistoryElapsedForCard } from 'utils/card';
import { Card } from 'components/Card';
import styles from './CardPage.module.scss';
import { ListItemMicrodata } from 'components/List/ListItemMicrodata';
import { Observable as ImpressionObservable } from 'contexts/ImpressionAnalyticsContext/Observable';
import { idFromPointer } from 'utils/pointer';
import { AnalyticsContextProvider } from 'contexts/AnalyticsContext';

type Props = {
  pageSize: number;
  pageIndex: number;
  source: Pointer;
  pageKey: string;
  listStyle: ListStyle | undefined;
  hidden?: boolean;
  filterParameters: Record<string, string>;
  activeCardIndex?: number | undefined;
};

const CardPage: React.FunctionComponent<Props> = ({
  pageIndex,
  pageSize,
  source,
  hidden,
  pageKey,
  listStyle,
  filterParameters,
  activeCardIndex,
}) => {
  const { cards, analyticsContext } = useCards({
    source,
    pageIndex,
    pageSize,
    filterParameters,
  });
  const { historyEntries } = useProgress(cards);
  const cardClassName = classNames(styles.listItem, hidden && styles.hidden);

  const [hiddenCards, setHiddenCards] = useState<string[]>([]);

  const hideCard = (key: string) => () =>
    setHiddenCards((prev) => [...prev, key]);

  return (
    <AnalyticsContextProvider context={analyticsContext}>
      {cards.map((card, index) => {
        const isActiveCard = activeCardIndex === index;
        const cardKey = getCardKey(card, pageKey);

        if (hiddenCards.includes(cardKey)) return null;
        const itemIndex = pageIndex * pageSize + index;

        return (
          <ListItemMicrodata
            className={cardClassName}
            aria-current={isActiveCard}
            key={cardKey}
            itemIndex={itemIndex}
            pointer={card.pointer}
          >
            <ImpressionObservable
              yleId={idFromPointer(card.pointer) || undefined}
              itemPosition={itemIndex}
              visible={!hidden}
            >
              <Card
                card={card}
                cardKey={cardKey}
                listStyle={listStyle}
                sizes="(min-width: 1920px) 18vw, (min-width: 1020px) 17vw, (min-width: 640px) 21vw, 35vw"
                historyElapsed={getHistoryElapsedForCard(card, historyEntries)}
                isActive={isActiveCard}
                hideCard={hideCard(cardKey)}
              />
            </ImpressionObservable>
          </ListItemMicrodata>
        );
      })}
    </AnalyticsContextProvider>
  );
};

export default CardPage;
