import Icon from 'assets/chevronRight.svg';
import classNames from 'classnames';
import { useTranslation } from 'hooks/useTranslation';
import React from 'react';
import styles from './ScrollButton.module.scss';

interface Props {
  direction: 'left' | 'right';
  hidden: boolean;
  onClick: () => void;
}

export const ScrollButton: React.FunctionComponent<Props> = ({
  direction,
  hidden,
  onClick,
}) => {
  const t = useTranslation();

  return (
    <button
      aria-label={direction === 'left' ? t('scrollLeft') : t('scrollRight')}
      className={classNames(
        styles.button,
        direction === 'left' && styles.buttonLeft
      )}
      hidden={hidden}
      onClick={onClick}
      tabIndex={-1}
    >
      <Icon className={styles.icon} />
    </button>
  );
};
