/* eslint-disable jsx-a11y/alt-text, @next/next/no-img-element */

import { Card, Image as ImageType, ListStyle } from '@yleisradio/areena-types';
import classNames from 'classnames';
import { CardLabels } from 'components/Card/CardLabels';
import { Container } from 'components/Container';
import { Controls } from 'components/Controls';
import { GridContainer, GridElement } from 'components/Grid';
import { Link } from 'components/Link';
import Image, { getImageProps, ImageProps } from 'next/image';
import React, { useState } from 'react';
import { filterLabelsByType } from 'utils/card';
import {
  backgroundImageLoader,
  loaderUrl,
  squareImageLoader,
} from 'utils/cloudinary';
import { sanitizeHtmlId } from 'utils/ui-helpers';
import styles from './HeroCard.module.scss';
import { Logo } from './Logo';

type HeroBackgroundProps = {
  image: ImageType;
  imageAspectRatio: ListStyle['image'];
};

const HeroBackground: React.FC<HeroBackgroundProps> = ({
  image,
  imageAspectRatio,
}) => {
  const [isLoaded, setLoaded] = useState(false);

  const common: ImageProps = {
    alt: '',
    className: classNames(
      styles.backgroundImage,
      isLoaded && styles.backgroundImageLoaded
    ),
    fill: true,
    priority: true,
    sizes: '100vw',
    src: loaderUrl(image),
  };
  const { props: mobile } = getImageProps({
    ...common,
    loader: backgroundImageLoader({
      aspectRatio: '1:1',
      isBlurred: imageAspectRatio === '1:1',
    }),
  });
  const {
    props: { srcSet: desktop },
  } = getImageProps({
    ...common,
    loader: backgroundImageLoader({
      aspectRatio: imageAspectRatio === '1:1' ? '1:1' : '16:9',
      isBlurred: imageAspectRatio === '1:1',
    }),
  });

  return (
    <picture>
      <source media="(min-width: 480px)" srcSet={desktop} />
      <img
        onLoad={() => {
          setLoaded(true);
        }}
        {...mobile}
      />
    </picture>
  );
};

type Props = {
  card: Card;
  cardKey: string;
  imageAspectRatio?: ListStyle['image'];
};

export const HeroCard: React.FunctionComponent<Props> = ({
  card,
  cardKey,
  imageAspectRatio,
}) => {
  const headingId = sanitizeHtmlId(cardKey + 'title');
  const highlightLabels = filterLabelsByType('highlight', card.labels);
  const controls = card.controls || [];
  const hasSquareImage = imageAspectRatio === '1:1';

  const rootClasses = classNames(
    styles.root,
    hasSquareImage ? styles.rootWithSquareImage : styles.rootWithNoSquareImage
  );

  return (
    <div className={rootClasses} role="group" aria-labelledby={headingId}>
      {card.image && (
        <div className={styles.backgroundImageWrapper}>
          <HeroBackground
            image={card.image}
            imageAspectRatio={imageAspectRatio}
          />
        </div>
      )}

      <div className={styles.content}>
        <Container>
          <GridContainer>
            {hasSquareImage && (
              <>
                <GridElement mobile={2} mobileLandscape={0} />
                <GridElement mobile={8} mobileLandscape={4} desktop={6}>
                  <div className={styles.image}>
                    {card.image && (
                      <Image
                        alt=""
                        src={loaderUrl(card.image)}
                        loader={squareImageLoader}
                        priority
                        fill
                        sizes="(min-width: 1400px) 22.5vw, (min-width: 480px) 30vw, 60vw"
                      />
                    )}
                  </div>
                </GridElement>
                <GridElement mobile={2} mobileLandscape={0} />
              </>
            )}

            <GridElement mobile={12} mobileLandscape={8}>
              <div className={styles.textContent}>
                {!hasSquareImage && card.overlayLogoImage && (
                  <div className={styles.logo}>
                    <Logo image={card.overlayLogoImage} />
                  </div>
                )}

                {highlightLabels.length > 0 && (
                  <div className={styles.highlightLabels}>
                    <CardLabels labels={highlightLabels} isHighlight />
                  </div>
                )}

                <h2 className={styles.title} dir="auto" id={headingId}>
                  {card.pointer ? (
                    <Link pointer={card.pointer} className={styles.link}>
                      {card.title}
                    </Link>
                  ) : (
                    card.title
                  )}
                </h2>

                {card.description ? (
                  <p className={styles.description} dir="auto">
                    {card.description}
                  </p>
                ) : null}

                {controls.length > 0 && (
                  <div className={styles.controls}>
                    <Controls controls={controls} as="button" size="sm" />
                  </div>
                )}
              </div>
            </GridElement>
          </GridContainer>
        </Container>
      </div>
      <div className={styles.overlap} />
    </div>
  );
};
