import { Label } from '@yleisradio/areena-types';
import { apiToCssColor } from 'utils/ui-helpers';
import styles from './ImageOverlay.module.scss';

interface ImageOverlayProps {
  overlay: Label;
}
export const ImageOverlay: React.FunctionComponent<ImageOverlayProps> = ({
  overlay,
}) => {
  const overlayColor =
    overlay.backgroundColor && apiToCssColor(overlay.backgroundColor);

  return (
    <div
      data-testid="image-overlay"
      style={{
        backgroundColor: overlayColor,
      }}
      className={styles.imageOverlay}
    >
      <span>{overlay.formatted}</span>
    </div>
  );
};
