import { Image as ImageType, ListStyle } from '@yleisradio/areena-types';
import Image from 'next/image';
import React from 'react';
import { loaderUrl, overlayLogoImageLoader } from 'utils/cloudinary';
import styles from './OverlayLogoImage.module.scss';

interface OverlayLogoImageProps {
  logoImage: ImageType | undefined;
  listStyle: ListStyle | undefined;
}

const getLogoImageClassName = (listStyle: ListStyle | undefined) => {
  switch (listStyle?.image) {
    case '1:1':
      return styles.squareLogoImage;
    case '16:9':
    default:
      return styles.rectangleLogoimage;
  }
};

export const OverlayLogoImage: React.FunctionComponent<
  OverlayLogoImageProps
> = ({ logoImage, listStyle }) => {
  if (!logoImage) return null;
  return (
    <Image
      key={logoImage.id}
      src={loaderUrl(logoImage)}
      alt={`${logoImage.alt || ''} logo`}
      loader={overlayLogoImageLoader}
      width={270}
      height={148}
      priority
      className={getLogoImageClassName(listStyle)}
    />
  );
};
