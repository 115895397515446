import { Card, HistoryResponse } from '@yleisradio/areena-types';
import { useEffect } from 'react';
import useSWR from 'swr';
import { useProgressCacheInvalidator } from 'contexts/ProgressCacheInvalidatorContext';
import { useTunnusContext } from 'contexts/TunnusContext';
import { fetchPlaybackHistory } from 'services/areena-api/fetchers';
import { getCardProgressIds } from 'utils/card';

interface HookReturn {
  readonly historyEntries: HistoryResponse | null;
}

type FetcherParameters = ['progress', programIds: string[]];

type FetcherType = (args: FetcherParameters) => Promise<HistoryResponse | null>;

const fetcher: FetcherType = ([, cardIds]) => fetchPlaybackHistory(cardIds);

export function useProgress(cardList: Card[]): HookReturn {
  const { isAuthenticated } = useTunnusContext();
  const programIds = getCardProgressIds(cardList);
  const key: FetcherParameters = ['progress', programIds];

  const { data: historyEntries = null, mutate: invalidate } = useSWR(
    isAuthenticated && key,
    fetcher
  );

  const { addInvalidator, deleteInvalidator } = useProgressCacheInvalidator();
  useEffect(() => {
    addInvalidator(invalidate);
    return () => deleteInvalidator(invalidate);
  }, [invalidate, addInvalidator, deleteInvalidator]);

  return {
    historyEntries,
  };
}
